<template>
  <div id="bg">
    <search-pic :type="type">
      <div style="display: flex;justify-content: space-around">
        <div class="left-box">
          <dv-border-box-12 class="leftBox">
            <div class="boxTitle">
              统计分析
            </div>
            <ul style="display: flex;justify-content: space-around">
              <li class="statisticBox">
                <div style="padding: 10px">
                  <span
                    class="dot1"
                  ></span>
                  <span
                    class="statisticTitle"
                  >本月保养台量</span>
                </div>
                <p class="statisticNum">{{statistic.maintainQuantity}}</p>
                <div style="font-size: 16px">
                  环比
                  <span style="color:#FF502E;font-size:16px">{{statistic.maintainChain}}</span>
                </div>
              </li>
              <li class="statisticBox">
                <div style="padding: 10px">
                  <span
                    class="dot2"
                  ></span>
                  <span
                    class="statisticTitle"
                  >本月急修台量</span>
                </div>

                <p class="statisticNum">{{statistic.repairReportQuantity}}</p>
                <div>环比 <span style="color:#FF502E;font-size:16px">{{statistic.repairReportChain}}</span></div>
              </li>
              <li class="statisticBox">
                <div style="padding: 10px">
                  <span
                    class="dot3"
                  ></span>
                  <span
                    class="statisticTitle"
                  >本月故障台量</span>
                </div>

                <p class="statisticNum">{{statistic.systemRepairQuantity}}</p>
                <div>环比 <span style="color:#FF502E;font-size:16px">{{statistic.systemRepairChain}}</span></div>
              </li>
            </ul>
          </dv-border-box-12>
          <dv-border-box-12 class="leftBox" style="height: 320px">
            <div id="teamwork"></div>
          </dv-border-box-12>
          <dv-border-box-12 class="leftBox" style="height: 320px">
            <div id="person"></div>
          </dv-border-box-12>
        </div>
        <div class="center-box">
          <dv-border-box-12 id="deviceRun" style="padding: 10px">
            <div style="padding: 12px;font-size: 24px;color: #fff;font-weight: 500">终端运行情况</div>
            <ul style="display: flex;justify-content: space-around;padding-top: 20px">
              <li style="text-align: center">
                <img class="logo" src="../../assets/img/newBigScreen/icon运行次数@2x.png"/>
                <p class="logoText">运行次数(次)</p>
                <p class="number">{{formatter(totalRunningCount)}}</p>
              </li>
              <li style="text-align: center">
                <img class="logo" src="../../assets/img/newBigScreen/icon运行时间@2x.png"/>

                <p class="logoText">运行时间（h）</p>
                <p class="number">{{formatter(totalRunningTime)}}</p>
              </li>
              <li style="text-align: center">
                <img class="logo" src="../../assets/img/newBigScreen/运行距离@2x.png"/>
                <p class="logoText">运行距离（km）</p>

                <p class="number">{{formatter(totalRunningDistance)}}</p>
              </li>
              <li style="text-align: center">
                <img class="logo" src="../../assets/img/newBigScreen/icon开关门次数@2x.png"/>
                <p class="logoText">开关门次数（次）</p>

                <p class="number">{{formatter(totalDoorOpeningCount)}}</p>
              </li>
            </ul>
          </dv-border-box-12>
          <dv-border-box-12 class="leftBox" style="height: 320px;width: 749px;">
            <div style="padding: 12px;font-size: 24px;color: #fff;font-weight: 500">品牌故障</div>
            <div id="brand" style="height: 290px;margin-top: -20px"></div>
          </dv-border-box-12>
          <dv-border-box-12 class="leftBox" style="height: 320px;width: 749px;">
            <div style="padding: 12px;font-size: 24px;color: #fff;font-weight: 500">年度维保趋势</div>
            <div id="year" style="height: 290px;margin-top: -20px"></div>
          </dv-border-box-12>
        </div>

        <div class="right-box">
          <dv-border-box-12 class="leftBox">
            <div class="boxTitle">
              困人统计
            </div>
            <ul style="display: flex;justify-content: space-around">
              <li class="peopleBox">
                <div style="padding: 10px">
                  <div>
                    <span
                      class="peopleNum"
                    >
                      {{people.tiringTimes}}
                    </span><span style="color: white">次</span>
                  </div>

                  <span
                    class="statisticTitle"
                  >困人次数</span>
                </div>
              </li>
              <li class="peopleBox">
                <div style="padding: 10px">
                  <div>
                    <span
                      class="peopleNum2"
                    >
                      {{people.avgArriveTime}}
                    </span><span style="color: white">min</span>
                  </div>

                  <span
                    class="statisticTitle"
                  >平均到达时间</span>
                </div>
              </li>
              <li class="peopleBox">
                <div style="padding: 10px">
                  <div>
                    <span
                      class="peopleNum2"
                    >
                      {{people.avgSaveTime}}
                    </span><span style="color: white">h</span>
                  </div>

                  <span
                    class="statisticTitle"
                  >平均救援时间</span>
                </div>
              </li>
            </ul>
            <div id="people" style="height: 200px;width: 520px;margin-top: -50px ">
            </div>
          </dv-border-box-12>
          <dv-border-box-12 class="leftBox" style="height: 320px">
            <div style="padding: 12px;font-size: 24px;color: #fff;font-weight: 500">维保故障率统计</div>

            <div style="display: flex">
              <div id="fault" style="height: 290px;width: 200px;margin-top: -20px"></div>
              <div style="width: 200px;margin-top: 50px">
                <div>
                  <span
                    style="display: inline-block;width: 12px;height: 12px;border: 1px solid #5470c6;border-radius: 100%"></span>
                  <span
                    style="width: 86px;height: 12px;font-size: 18px;font-family: Source Han Sans CN, Source Han Sans CN-Regular;
font-weight: 400;
text-align: left;
color: #ffffff;
line-height: 47px;
letter-spacing: 0.3px">按时保养完成率</span> <span
style="width: 33px;
height: 10px;
font-size: 18px;
font-family: Roboto, Roboto-Regular;
font-weight: 500;
text-align: left;
color: #5470c6;
line-height: 47px;">{{fault.completeRate}}%</span>
                </div>
                <div>
                  <span
                    style="display: inline-block;width: 12px;height: 12px;border: 1px solid #91cc75;border-radius: 100%"></span>
                  <span
                    style="width: 86px;height: 12px;font-size: 18px;font-family: Source Han Sans CN, Source Han Sans CN-Regular;
font-weight: 400;
text-align: left;
color: #ffffff;
line-height: 47px;
letter-spacing: 0.3px">故障率</span> <span
style="width: 33px;
height: 10px;
font-size: 18px;
font-family: Roboto, Roboto-Regular;
font-weight: 500;
text-align: left;
color: #91cc75;
line-height: 47px;">{{fault.faultRate*1000}}‰</span>
                </div>
                <div>
                  <span
                    style="display: inline-block;width: 12px;height: 12px;border: 1px solid #fac858;border-radius: 100%"></span>
                  <span
                    style="width: 86px;height: 12px;font-size: 18px;font-family: Source Han Sans CN, Source Han Sans CN-Regular;
font-weight: 400;
text-align: left;
color: #ffffff;
line-height: 47px;
letter-spacing: 0.3px">误报率</span> <span
style="width: 33px;
height: 10px;
font-size: 18px;
font-family: Roboto, Roboto-Regular;
font-weight: 500;
text-align: left;
color: #fac858;
line-height: 47px;">{{fault.falseAlarmRate}}%</span>
                </div>
              </div>
            </div>
          </dv-border-box-12>
          <div>
            <dv-border-box-12 class="leftBox" style="height: 320px">
              <div>
                <span style="padding: 12px;font-size: 24px;color: #fff;font-weight: 500;display: inline-block">电梯医院</span>
              </div>

              <dv-scroll-board :config="configOrder" style="height: 240px;"></dv-scroll-board>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </search-pic>
  </div>
</template>

<script>
  import SearchPic from "@/views/template/SearchPic";
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";

  export default {
    components:{SearchPic},
    data() {
      return{
        option:{
          indicator:{},
        },
        configOrder:{
          header: ["<span>楼盘</span>", "<span>楼宇</span>", "<span>故障次数</span>"],
          data: [
            ["行1列1", "行1列2", "行1列3" ],
            ["行1列1", "行1列2", "行1列3" ],
            ["行1列1", "行1列2", "行1列3" ],
            ["行1列1", "行1列2", "行1列3" ],
            ["行1列1", "行1列2", "行1列3" ],
            ["行1列1", "行1列2", "行1列3" ],
            ["行1列1", "行1列2", "行1列3" ],
            ["行1列1", "行1列2", "行1列3" ],

          ],
          rowNum:5,
          oddRowBGC:"#050e49",
          evenRowBGC:"#050e49",
        },
        fault:{},
        totalRunningCount:1,
        totalRunningTime:1,
        totalRunningDistance:1,
        totalDoorOpeningCount:1,
        people:{},
        statistic:{},
      };
    },
    mounted() {
      // this.$nextTick(()=>{
      //   this.drawStatisticPic();
      // });
      this.getData();
      this.getFault();
      this.getTeamData();
      this.getPersonData();
      this.getBrand();
      this.getYear("");
      this.getHospital();
      this.getStatisticDataL();
      this.getPeople();
      window.setTimeout(()=>{
        this.drawStatisticPic();
      },1000);
      // this.drawStatisticPic();
    },
    methods:{
      getStatisticDataL() {
        let http =   this.$http.get("/big-screen/maintenance-capacity-statistics");

        http.then(data => {
          console.log(data,2142);
          this.statistic= data;

          window.setTimeout(()=>{
            // this.drawPeoplePic(arr);
            // this.drawFault(data.completeRate,data.faultRate,data.falseAlarmRate);
          },1000);

        }).catch(() => {
          // this.status = "error";
        });
      }
      ,
      getData() {
        let http =   this.$http.get("/big-screen/month-device-operation");
        http.then(data => {
          this.totalRunningCount=data.totalRunningCount;
          this.totalRunningTime=data.totalRunningTime;
          this.totalRunningDistance=data.totalRunningDistance;
          this.totalDoorOpeningCount=data.totalDoorOpeningCount;

        }).catch(() => {
          // this.status = "error";
        });
      },
      getTeamData() {
        let http =   this.$http.get("/big-screen/month-team-maintenance-statistics",{number:3});
        http.then(data => {
          if (data instanceof Array) {
            const initNum=100;
            const name=  [];
            const data1=  [];
            const data2=  [];
            const list = data.sort(function(a,b) {
              return (a.repairQuantity+a.maintainQuantity)-(b.repairQuantity+b.maintainQuantity);
            });
            list.forEach((item,index)=>{
              if (index<initNum) {
                name.push(item.teamName);
                data1.push(item.repairQuantity);
                data2.push(item.maintainQuantity);
              }else if (index===initNum) {
                name.push("其他");
                data1.push(item.repairQuantity);
                data2.push(item.maintainQuantity);
              }else {
                data1[initNum]+=item.repairQuantity;
                data2[initNum]+=item.maintainQuantity;
              }
            });
            window.setTimeout(()=>{
              this.drawTeamPic(data1,data2,name);
            },1000);

          }
        }).catch(() => {
          // this.status = "error";
        });
      },
      getBrand() {
        let http =   this.$http.get("/big-screen/brand-fault");

        http.then(data => {
          console.log(data,214);
          const name =[];
          const list =[];
          if (data instanceof Array) {
            data.forEach(item=>{
              name.push(item.brandNameDesc);
              list.push(item.faultQuantity);
            });
          }
          window.setTimeout(()=>{
            this.drawBrand(name,list);
          },1000);

        }).catch(() => {
          // this.status = "error";
        });
      },
      getFault() {
        let http =   this.$http.get("/big-screen/maintenance-prod-statistics");

        http.then(data => {
          console.log(data,214);
          this.fault= data;
          window.setTimeout(()=>{
            this.drawFault(data.completeRate,data.faultRate,data.falseAlarmRate);
          },1000);

        }).catch(() => {
          // this.status = "error";
        });
      },
      getPeople() {
        let http =   this.$http.get("/big-screen/tiring-statistics");

        http.then(data => {
          this.people= data;
          const arr =[];
          const name = [];
          if (data.trendVos && data.trendVos instanceof Array) {
            data.trendVos.forEach(item=>{
              arr.push(item.repairQuantity);
              name.push(item.month);
            });
          }
          window.setTimeout(()=>{
            this.drawPeoplePic(arr,name);
            // this.drawFault(data.completeRate,data.faultRate,data.falseAlarmRate);
          },1000);

        }).catch(() => {
          // this.status = "error";
        });
      },
      getHospital() {
        let http =   this.$http.get("/big-screen/elevator-hospital/list");

        http.then(data => {
          console.log(data,493);
          const arr = [];

          if (data instanceof Array) {

            data.forEach(item=>{
              arr.push([item.realEstateName,item.buildingName,item.faultQuantity]);
            });
          }

          this.configOrder.data=arr;
          // this.config.data=arr;
          this.configOrder = {...this.configOrder};
        }).catch(() => {
          // this.status = "error";
        });
      },
      getYear() {

        let http =   this.$http.get("/big-screen/year-maintenance-trend");

        http.then(data => {
          console.log(data,212);
          const name =[];
          const list =[];
          const list2 =[];
          const list3 =[];
          const list4 =[];
          if (data instanceof Array) {
            data.forEach(item=>{
              name.push(item.month);
              list.push(item.repairQuantity);
              list2.push(item.maintainQuantity);
              list3.push(item.repairChain);
              list4.push(item.maintainChain);
            });
          }
          window.setTimeout(()=>{
            this.drawYear(name,list,list2,list3,list4);
          },1000);

        }).catch(() => {
          // this.status = "error";
        });
      },
      getPersonData() {
        let http =   this.$http.get("/big-screen/employee-maintenance-statistics",{number:3});

        http.then(data => {
          if (data instanceof Array) {
            const initNum=10000;
            const name=  [];
            const data1=  [];
            const data2=  [];
            const list = data.sort(function(a,b) {
              return (a.repairQuantity+a.maintainQuantity)-(b.repairQuantity+b.maintainQuantity);
            });
            list.forEach((item,index)=>{
              if (index<initNum) {
                name.push(item.employeeName);
                data1.push(item.repairQuantity);
                data2.push(item.maintainQuantity);
              }else if (index===initNum) {
                name.push("其他");
                data1.push(item.repairQuantity);
                data2.push(item.maintainQuantity);
              }else {
                data1[initNum]+=item.repairQuantity;
                data2[initNum]+=item.maintainQuantity;
              }
            });
            window.setTimeout(()=>{
              this.drawPersonPic(data1,data2,name);
            },1000);

          }
        }).catch(() => {
          // this.status = "error";
        });
      },
      drawStatisticPic() {
        const chart = echarts.init(document.querySelector("#statisticPic1"),tdTheme);
        const option = {

          xAxis: {
            type: "category",
            show:false,
          },
          yAxis: {
            type: "value",
            show:false,
            min:5,

          },
          series: [
            {
              data: [1,2,10,59,89,5,67,8,9,8,7,6,5,4,3,3,2,2,1,1],
              type: "line",
              smooth: true,
              showSymbol: false,
            },
          ],
        };

        chart.setOption(option);
      },

      drawPeoplePic(data,name=[]) {
        const chart = echarts.init(document.querySelector("#people"),tdTheme);
        const option = {

          xAxis: {
            type: "category",
            data: name,
          },
          yAxis: {
            type: "value",
            show:true,

          },
          series: [
            {
              data,
              type: "bar",
              smooth: true,
              showSymbol: false,
              areaStyle: {},
            },
          ],
        };

        chart.setOption(option);
      },
      drawTeamPic(data1,data2,name) {
        const chart = echarts.init(document.querySelector("#teamwork"),tdTheme);
        const option =  {
          title: {
            text: "维保队工作统计",
            textStyle:{
              color:"#fff",
              fontSize:"24px",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {},
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            show:false,
          },
          yAxis: {
            type: "category",
            data: name,
          },
          series: [
            {
              name: "维修",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data1,
            },
            {
              name: "保养",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data2,
            },

          ],
        };
        option.series[0].radius = ["40%", "70%"];

        chart.setOption(option);
      },
      drawPersonPic(data1,data2,name) {
        const chart = echarts.init(document.querySelector("#person"),tdTheme);
        const option =  {
          title: {
            text: "维保队员工作统计",
            textStyle:{
              color:"#fff",
              fontSize:"24px",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {},
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            show:false,
          },
          yAxis: {
            type: "category",
            data: name,
          },
          series: [
            {
              name: "维修",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data1,
            },
            {
              name: "保养",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data2,
            },

          ],
        };
        option.series[0].radius = ["40%", "70%"];

        chart.setOption(option);
      },
      drawBrand(name,data) {
        const chart = echarts.init(document.querySelector("#brand"),tdTheme);
        console.log(data);
        const option =  {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          xAxis: {
            type: "category",
            data: name,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data,
              type: "bar",
              label: {
                show: true,
                position: "top",
                color:"#fff",
              },
              itemStyle:{
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#E45624",
                  },
                  {
                    offset: 1,
                    color: "#F18C45",
                  },
                ]),

              },
            },
          ],
        };

        chart.setOption(option);
      },
      drawFault(vaule1,value2,value3) {
        const chart = echarts.init(document.querySelector("#fault"),tdTheme);
        const gaugeData = [
          {
            value: vaule1,

          },
          {
            value: value2,

          },
          {
            value: value3,
          },
        ];
        const option = {
          series: [
            {
              type: "gauge",
              startAngle: 90,
              endAngle: -270,
              pointer: {
                show: false,
              },
              progress: {
                show: true,
                overlap: false,
                roundCap: true,
                clip: false,
                itemStyle: {
                  borderWidth: 1,
                  borderColor: "#464646",
                },
              },
              axisLine: {
                lineStyle: {
                  width: 40,
                },
              },
              splitLine: {
                show: false,
                distance: 0,
                length: 10,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
                distance: 50,
              },
              data: gaugeData,
              detail: {
                show:false,
                fontSize: 14,
              },
            },
          ],
        };

        chart.setOption(option);
      },
      drawYear(name,data,data2) {
        const chart = echarts.init(document.querySelector("#year"),tdTheme);
        console.log(data);
        const  option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ["line", "bar"] },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          legend: {
            data: ["Evaporation", "Precipitation", "Temperature"],
          },
          xAxis: [
            {
              type: "category",
              data: name,
              axisPointer: {
                type: "shadow",
              },
            },
          ],
          yAxis: [
            {
              type: "value",

            },
            {
              type: "value",


            },
          ],
          series: [
            {
              name: "维修数量",
              type: "bar",

              data: data,
            },
            {
              name: "保养数量",
              type: "bar",

              data: data2,
            },
            {
              name: "维修数量",
              type: "line",
              yAxisIndex: 1,
              data: data,
            },
            {
              name: "保养数量",
              type: "line",
              yAxisIndex: 1,
              data: data2,
            },
          ],
        };

        chart.setOption(option);
      },
      formatter(number) {
        const str = number+"";
        const numbers = str.split("").reverse();
        const segs = [];

        while (numbers.length) segs.push(numbers.splice(0, 3).join(""));

        return segs.join(",").split("").reverse().join("");
      },
    },
  };
</script>

<style scoped>
#bg{
  width: 100%;
  height: 100%;
  padding: 16px 16px 0 16px;
  background-image: url("../../assets/img/newBigScreen/组 24.png");
  background-size: cover;
  background-position: center center;
}
.left-box{
  width: 30%;
}
.center-box{
  width: 39%;
}
.right-box{
  width: 30%;
  margin-left: 38px;
}
.leftBox{
  width: 530px;
  height: 268px;
  background: #040d40;
  padding: 10px;
}
.boxTitle{
  padding: 10px;font-size: 24px;color: #fff;font-weight: 500
}
.statisticBox{
  padding-top: 25px;
  text-align: center;
  width: 152px;
  height: 174px;
  background: #0a164b;
  margin: 0 5px;
}
.dot1{
  display: inline-block;width: 8px;
  height: 8px;
  background: #2eedff;
}
.dot2{
  display: inline-block;width: 8px;
  height: 8px;
  background: #FF502E;
}.dot3{
  display: inline-block;width: 8px;
  height: 8px;
  background: #F6CB3C;
}
.statisticTitle{
  width: 95px;
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  /*line-height: 67px;*/
}
.statisticNum{
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
}
#teamwork{
  height: 310px;
}
#person{
  height: 310px;
}
#deviceRun{
  width: 749px;
  height: 268px
}

.peopleBox{
  width: 152px;
  height: 79px;
  background: rgba(12,32,91,0.50);
}
.peopleNum{
  width: 50px;height: 23px;
  font-size: 32px;font-family: Roboto, Roboto-Regular;font-weight: 400;text-align: left;color: #dd6b33;line-height: 36px;
}
.peopleNum2{
  width: 50px;height: 23px;
  font-size: 32px;font-family: Roboto, Roboto-Regular;font-weight: 400;text-align: left;color: #04D3D5;line-height: 36px;
}
.peopleNum3{
  width: 50px;height: 23px;
  font-size: 32px;font-family: Roboto, Roboto-Regular;font-weight: 400;text-align: left;color: #dd6b33;line-height: 36px;
}
.number{
  margin-top: 10px;
  text-align: left;
  width: 100px;
  height: 25px;
  font-size: 20px;
  font-family: Roboto, Roboto-Bold;
  font-weight: 700;
  color: #00d5f5;
  line-height: 36px;
}
.logo{
  width: 82px;height: 48px
}
</style>
